.custom-markdown {
  font-family: Inter, Arial, Helvetica, sans-serif;

  h1 {
    text-align: center;
    color: #101828;
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
    margin: 0;
  }

  h2 {
    text-align: center;
    color: #667085;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    margin: 0;
  }

  h3 {
    color: #101828;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    margin: 0;
  }

  p {
    font-size: 14px;
    line-height: 20px;
  }

  a {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    padding: 0 8px;
    text-align: left;
    text-decoration: none;
    color: #0500ff;
  }

  strong {
    font-weight: 700;
  }

  ol {
    color: #101828;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    padding: 0 24px;
  }

  ul {
    color: #667085;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
  }

  li {
    margin: 5px 0;
  }

  hr {
    margin: 16px 0;
    border: 0;
    border-top: 1px solid #eeeeee;
  }

  pre {
    display: block;
    padding: 20px;
    line-height: 28px;
    word-break: break-word;
  }

  code,
  pre {
    background-color: #f5f5f5;
    font-size: 14px;
    border-radius: 0;
    overflow-x: auto;
  }

  code {
    padding: 3px 0;
    margin: 0;
    word-break: normal;
  }

  code:after,
  code:before {
    letter-spacing: 0;
  }

  blockquote {
    position: relative;
    margin: 16px 0;
    padding: 5px 8px 5px 30px;
    background: none repeat scroll 0 0 rgba(102, 128, 153, 0.05);
    color: #333;
    border: none;
    border-left: 10px solid #d6dbdf;
  }

  img,
  video {
    display: block;
    max-width: 100%;
    margin: 0 auto;
  }

  table {
    font-size: 14px;
    line-height: 1.7;
    max-width: 100%;
    overflow: auto;
    border: 1px solid #f6f6f6;
    border-collapse: collapse;
    border-spacing: 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }

  table td,
  table th {
    word-break: break-all;
    word-wrap: break-word;
    white-space: normal;
  }

  table tr {
    border: 1px solid #efefef;
  }

  table tr:nth-child(2n) {
    background-color: transparent;
  }

  table th {
    text-align: center;
    font-weight: 700;
    border: 1px solid #efefef;
    padding: 10px 6px;
    background-color: #f5f7fa;
    word-break: break-word;
  }

  table td {
    border: 1px solid #efefef;
    text-align: left;
    padding: 10px 15px;
    word-break: break-word;
    min-width: 60px;
  }
}
