.dashboard-month {
  width: 20%;
  float: right;
  margin-right: 6%;
}

div.MuiCalendarPicker-root {
  border: 1px solid #dadfe4;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  overflow: hidden;
}

div.MuiCalendarPicker-root button.Mui-selected.MuiPickersDay-root {
  background-color: #9e77ed;
}

div.MuiCalendarPicker-root button.Mui-selected:hover {
  background-color: #9e77ed;
}

div.MuiCalendarPicker-root div.MuiYearPicker-root {
  padding-bottom: 35px;
}

div.MuiCalendarPicker-root div.PrivatePickersSlideTransition-root {
  min-height: auto;
  height: auto;
  padding-bottom: 33px;
}

div.MuiCalendarPicker-root div.MuiDayPicker-monthContainer {
  position: relative;
}
