@font-face {
  font-family: 'Inter';
  src: url('../assets/fonts/inter-variable.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('../assets/fonts/inter-variable.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('../assets/fonts/inter-variable.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('../assets/fonts/inter-variable.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Roobert-Light';
  src: url('../assets/fonts/roobert-light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Roobert-Regular';
  src: url('../assets/fonts/roobert-regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Roobert-Medium';
  src: url('../assets/fonts/roobert-medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

:root {
  --font-family-inter-variable: 'Inter', sans-serif;
  --font-family-roobert-regular-variable: 'Roobert-Regular', sans-serif;
  --font-family-roobert-light-variable: 'Roobert-Light', sans-serif;
  --font-family-roobert-medium-variable: 'Roobert-Medium', sans-serif;
}

body {
  font-family: var(--font-family-inter-variable);
}
